import React, { ReactElement } from 'react'
import MetaTags from '@objects/metatags'
import { Container } from '@mui/material'

import Headline from '@objects/headline'
import Paragraph from '@objects/paragraph'

interface IRootIndex {
  pageContext: Record<string, string>
}

export default function RootIndex({ pageContext }: IRootIndex): ReactElement {
  return (
    <Container sx={{ marginTop: 15 }}>
      <MetaTags locale={pageContext.locale} meta={{ noIndex: true }} />
      <Headline variant="h1">Lost in space?</Headline>
      <Paragraph sx={{ marginTop: 10, marginBottom: 12.5 }}>
        No problem! That way please :)
      </Paragraph>
    </Container>
  )
}
