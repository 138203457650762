import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { SxProps } from '@mui/system/styleFunctionSx'

const P = styled('p')({
  marginBottom: 8,
})

export type ParagraphProps = GT.IReactDefaultProps & {
  sx?: SxProps
}

export default function Paragraph({
  children,
  sx,
}: ParagraphProps): ReactElement {
  return <P sx={sx}>{!!children && children}</P>
}
