import React, { ReactElement } from 'react'
import { SxProps } from '@mui/system/styleFunctionSx'
import Typography from '@mui/material/Typography'
import { Variant } from '@mui/material/styles/createTypography'

export type HeadlineProps = GT.IReactDefaultProps & {
  level?: number
  id?: string
  html?: string
  variant?: Variant
  sx?: SxProps
}

export default function Headline({
  children,
  id,
  html,
  sx,
  variant,
  ...props
}: HeadlineProps): ReactElement {
  function renderChildren() {
    if (html) return null
    return children
  }
  const Props = {
    id: id,
    sx: sx,
    ...(html && {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    }),
    ...props,
  }

  return (
    <Typography variant={variant || 'h2'} {...Props}>
      {renderChildren()}
    </Typography>
  )
}
